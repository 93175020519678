<template>
    <div class="label__list-item" :style="style">
        <div class="label__list-item-container">
            <div class="title">{{ data.label }}</div>
            <router-link :to="data.link" class="link">
                {{ sysLabelGetter('go_to_product') }}
                <font-awesome-icon icon="chevron-right" />
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState("main", {
            activeBrandLabel: "activeBrandLabel",
        }),
        ...mapGetters("main", {
            sysLabelGetter:'sysLabelGetter',
        }),
        style() {
            return this.$getBrandColor(this.activeBrandLabel);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.label__list-item {
    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 500px;

        .title {
            color: $dark_text;
            font-weight: bold;
            font-size: 1rem;
        }

        .link {
            color: var(--brand-color);
            font-weight: 300;
            font-size: 1rem;
            text-decoration: none;

            svg {
                font-size: 0.7rem;
            }
        }
    }
}
</style>