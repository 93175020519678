<template>
    <div class="label__list">
        <div>
            <p class="header">
                <template v-if="title">
                    {{ title }}
                </template>
                <template v-else>
                    {{ sysLabelGetter('this_parts_can_be_found_in_following_products') }}
                </template>
            </p>
        </div>
        <LabelListItem v-for="(item, idx) in list" :key="idx" :data="item" />
    </div>
</template>

<script>
import LabelListItem from "@/components/LabelListItem.vue";
import { mapGetters } from "vuex";

export default {
    props: {
        list: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: "",
        },
    },
    components: {
        LabelListItem,
    },
    computed: {
        ...mapGetters("main", {
            sysLabelGetter:'sysLabelGetter',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.label__list {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .header {
        color: $light_text;
    }

    ::v-deep .label__list-item {
        &:not(:last-child) {
            .label__list-item-container {
                border-bottom: 1px solid $border_color;
            }
        }
    }
}
</style>
