<template>
    <div class="order-info" :style="style">

        <div class="order-info__header">
            
            <div class="header">
                <h4 class="position" v-if="data.position && categoryId">
                    {{ sysLabelGetter('pos') }} {{ data.position[categoryId] }}
                </h4>
                <h4 class="article_no">{{ sysLabelGetter('order_nr') }}: {{ data.article_no }}</h4>
            </div>
            <p class="title">
                {{ data.label }}
            </p>
            <p class="footnote">
                {{ data.footnote }}
            </p>

        </div>

        <div class="order-info__body body">

            <div class="body__left">
                <img
                    alt=""
                    :src="$image(data.image, {extension:'jpg'})"
                    @click="$bvModal.show(`image-popup__order-info-${identifier}`)"
                />
            </div>

            <div class="body__right">
                <div class="body__right__text">
                    <div class="item" v-if="data.weight && data.weight">
                        <div>{{ sysLabelGetter('weight') }}:</div>
                        <div class="item-color">{{ data.weight }}</div>
                    </div>
                    <!-- <p class="item ml-3" v-if="data.price && data.price !== '-'">
                                {{ sysLabelGetter('price') }}: <span class="item-color">{{ data.price }}</span>
                            </p> -->
                </div>

                <b-button
                    v-if="buttonStatus"
                    class="body__right__button no-print"
                    @click="updatePrintListMt({ id: data.id, action: 'remove' })"
                    variant="danger"
                >
                    {{ sysLabelGetter('remove_from_print_list') }}
                </b-button>

                <b-button
                    v-else
                    class="body__right__button no-print"
                    @click="updatePrintListMt({ id: data.id, action: 'add' })"
                    :class="brandBtnVariant"
                >
                    {{ sysLabelGetter('add_to_print_list') }}
                </b-button>
            </div>

        </div>

        <ImagePopup :id="`order-info-${identifier}`" :image="data.image" extension="jpg"/>
    </div>
</template>

<script>
import ImagePopup from "@/components/ImagePopup.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
    name: "OrderInfo",
    components: {
        ImagePopup,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        categoryId: {
            type: [String, Object],
            default: null
        },
        identifier: {
            type: String,
            required: true
        },
    },
    computed: {
        ...mapState("main", {
            activeBrandLabel: 'activeBrandLabel',
            printList: "printList",
            brands: "brands",
            parts: "parts",
        }),
        ...mapGetters("main", {
            sysLabelGetter:'sysLabelGetter',
        }),
        buttonStatus() {
            let output = false;
            if (this.printList.includes(this.data.id)) output = true;
            return output;
        },
        style() {
            return this.$getBrandColor( this.activeBrandLabel );
        },
        brandBtnVariant() {
            if ( this.activeBrandLabel.toLowerCase().includes('sesam') ) return 'btn-sesam';
            return 'btn-edscha';
        },
    },
    methods: {
        ...mapMutations("main", {
            updatePrintList: "updatePrintList",
        }),
        updatePrintListMt(data) {
            let list = [...this.printList];
            if (data.action === "add" && !this.printList.includes(data.id)) {
                list.push(data.id);
                this.$localStorage.setItem("print-list", list);
            } else if (data.action === "remove" && this.printList.includes(data.id)) {
                list.splice(list.indexOf(data.id), 1);
                this.$localStorage.setItem("print-list", list);
            }
            this.updatePrintList(list);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.order-info {
    width: 100%;
    margin: 0 auto;
    padding: 25px 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $border_color;
    }

    &__header {
        .header {
            width: 100%;
            font-weight: bold;
            margin-bottom: 15px;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;

            .position {
                font-weight: inherit;
                font-size: inherit;
            }
            .article_no {
                margin-left: auto;
                min-width: 20ch;
                padding-right: 5px;
                font-size: inherit;
                font-weight: inherit;
                text-align: right;
            }
        }
        .title {
            color: $light_text;
        }
        .footnote {
            font-size: 0.875rem;
            max-height: 16px;
            overflow: hidden;
            color: var(--brand-color);
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        min-width: 150px;
        align-self: flex-end;

        .body {
            &__left {
                flex: 0 0 200px;

                img {
                    width: 100%;
                    min-height: 150px;
                    min-width: 150px;
                    object-fit: cover;
                    cursor: pointer;
                }
            }

            &__right {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;

                &__text {
                    display: flex;
                    justify-content: flex-end;

                    .item {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: $light_text;
                    }
                    .item-color {
                        font-weight: bold;
                        color: var(--brand-color);
                    }
                }

                &__button {
                    display: block;
                    min-width: 270px;
                }
            }
        }
    }
}

@media print {
    .no-print {
        display: none;
    }
}

</style>
